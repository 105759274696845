require('./bootstrap');

// // // //import VueCookies from 'vue-cookies';
// // import Notifications from 'vue-notification';


// // // //Vue.use(VueCookies);
// // Vue.use(Notifications);

// // /* Register our new component: */
// // Vue.component('contact-form', require('./components/ContactForm.vue').default);
// // Vue.component('recaptcha', require('./components/Recaptcha.vue').default);
// // Vue.component('login', require('./components/LoginForm.vue').default);
// // Vue.component('register', require('./components/SignupForm.vue').default);
// // Vue.component('profile-layout', require('./components/UserProfileLayout.vue').default);
// // Vue.component('profile-edit', require('./components/ProfileEdit.vue').default);
// // Vue.component('profile-pass', require('./components/ProfilePass.vue').default);
// // Vue.component('upload-home-img', require('./components/UploadHomeimg.vue').default);


// // import Vue from 'vue';
// // const app = new Vue({
// //     el: '#app'
// // });



// import { createApp } from 'vue';


// import { VueReCaptcha } from "vue-recaptcha-v3";



// // import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

// // const component = {
// //   setup() {
// //     const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

// //     const recaptcha = async () => {
// //       // (optional) Wait until recaptcha has been loaded.
// //       await recaptchaLoaded()

// //       // Execute reCAPTCHA with action "login".
// //       const token = await executeRecaptcha('login')

// //       // Do stuff with the received token.
// //     }

// //     return {
// //       recaptcha
// //     }
// //   },
// //   template: '<button @click="recaptcha">Execute recaptcha</button>'
// // }


// const app = createApp({});











// import Toast from "vue-toastification";
// // Import the CSS or use your own!
// import "vue-toastification/dist/index.css";

// const options = {
//     // You can set your default options here
// };
// app.use(Toast, options);

// /* Register our new component: */
// app.component('contact-form', require('./components/ContactForm.vue').default);
// //app.component('recaptcha', require('./components/Recaptcha.vue').default);
// app.component('login', require('./components/LoginForm.vue').default);
// app.component('register', require('./components/SignupForm.vue').default);
// app.component('profile-layout', require('./components/UserProfileLayout.vue').default);
// app.component('profile-edit', require('./components/ProfileEdit.vue').default);
// app.component('profile-pass', require('./components/ProfilePass.vue').default);
// app.component('upload-home-img', require('./components/UploadHomeimg.vue').default);
// app.component('upload-profile-img', require('./components/UploadProfileimg.vue').default);
// app.component('profile-social', require('./components/ProfileSocial.vue').default);
// app.component('create-article', require('./components/CreateArticle.vue').default);

// app.use(VueReCaptcha, { siteKey: "6LcVEycbAAAAALDIO10W9uajxkx5KS7lyTYCMtCl" }).mount('#app');


// //createApp(App).mount("#app")
